import React from "react";
// core components
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

const initialState = {    
    accessToken: localStorage.getItem("accessToken"),
    idUsuario: localStorage.getItem("idUsuario"),
    usuario: localStorage.getItem("usuario"),
    nombreCorto: localStorage.getItem("nombreCorto"),
    avatar: localStorage.getItem("avatar"),
    perfil: localStorage.getItem("perfil"),
    zona: localStorage.getItem("zona"),
    pantallaBloqueada: localStorage.getItem("pantallaBloqueada"),
    error: null,
    loading: null,
}

const authReducer = (state = initialState, action) => {

    switch(action.type){
        case "LOGIN_CORRECTO": {
            localStorage.setItem("accessToken", action.payload.accessToken);
            localStorage.setItem("idUsuario", action.payload.idUsuario);
            localStorage.setItem("usuario", action.payload.usuario);
            localStorage.setItem("nombreCorto", action.payload.nombreCorto);
            localStorage.setItem("avatar", action.payload.avatar);
            localStorage.setItem("perfil", action.payload.perfil);
            localStorage.setItem("zona", action.payload.zona);
            localStorage.removeItem("pantallaBloqueada");

            return {
                ...state,
                accessToken: action.payload.accessToken,
                idUsuario: action.payload.idUsuario,
                usuario: action.payload.usuario,
                nombreCorto: action.payload.nombreCorto,
                avatar: action.payload.avatar,
                perfil: action.payload.perfil,
                zona: action.payload.zona,
                pantallaBloqueada: null,
                error: null,
                loading: null,
            }
        }
        case "LOGIN_ERROR": {
            return {
                ...state,
                error: action.payload.error,
                loading: null,
            }
        }
        case "LOGOUT_CORRECTO": {
            localStorage.removeItem("accessToken");

            return {
                ...state,
                accessToken: null,
            }
        }
        case "BLOQUEO_CORRECTO":{
            localStorage.removeItem('accessToken');
            localStorage.setItem('pantallaBloqueada', true);

            return {
                ...state,
                accessToken: null,
                pantallaBloqueada: true,
            }
        }
        case "DEBLOQUEO_CORRECTO": {
            localStorage.setItem("accessToken", action.payload.accessToken);
            localStorage.removeItem("pantallaBloqueada");

            return {
                ...state,
                accessToken: action.payload.accessToken,
                pantallaBloqueada: null,
                error: null,
                loading: null,
            }
        }
        case "DEBLOQUEO_ERROR": {
            return {
                ...state,
                error: action.payload.error,
                loading: null,
            }
        }
        case "LIMPIAR_ERROR": {
            return {
                ...state,
                error: null,
            }
        }
        case "AUTH_LOADING": {
            return {
                ...state,
                loading: (<CustomLinearProgress color="info" />),
            }
        }        
        default:
            return state;        
    }
}

export default authReducer;