import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { unlock, clearError } from "../../redux/actions/authActions";
import { verifyLength } from "../../utils/commonFunctions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";

// @material-ui/icons
import Error from "@material-ui/icons/Error";

import defaultAvatar from "assets/img/login_avatar.png";

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

const useStyles = makeStyles(styles);

function LockScreenPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [unlockPassword, setUnlockPassword] = React.useState("");
  const [unlockPasswordState, setUnlockPasswordState] = React.useState("");  
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const { pantallaBloqueada, nombreCorto, avatar, loading, unlockError } = props;  

  const handleUnlock = () => {
    let bFormIsValid = true;
    if (unlockPassword === "") {
      setUnlockPasswordState("error");
      bFormIsValid = false;
    }
    if(bFormIsValid){
      props.unlock({ usuario: props.usuario, password: unlockPassword});
    }    
  };

  if(!pantallaBloqueada) return <Redirect to="/" />

  const errorNotification = unlockError ? (
    <Snackbar
      place="tc"
      color="danger"
      icon={Error}
      message={unlockError}
      open
      closeNotification={() => props.clearError()}
      close
    />
  ) : null;

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  return (
    <div className={classes.container}>
      {errorNotification}
      <form onSubmit={handleSubmit}>
        <Card
          profile
          className={classes.customCardClass + " " + classes[cardAnimaton]}
        >
          <CardAvatar profile className={classes.cardAvatar}>
            <a href="#pablo" onClick={e => e.preventDefault()}>
              <img src={(avatar === "null" || !avatar) ? defaultAvatar : avatar} alt="..." />
            </a>
          </CardAvatar>
          <CardBody profile>
            <h4 className={classes.cardTitle}>{nombreCorto}</h4>
            <CustomInput
              success={unlockPasswordState === "success"}
              error={unlockPasswordState === "error"}                        
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputAdornmentIcon}>
                      lock_outline
                        </Icon>
                  </InputAdornment>
                ),                
                type: "password",
                onChange: event => {
                  if (verifyLength(event.target.value, 1)) {
                    setUnlockPasswordState("success");
                  } else {
                    setUnlockPasswordState("error");
                  }
                  setUnlockPassword(event.target.value);
                },
                onKeyDown: event => {
                      if (event.key === 'Enter') {
                        handleUnlock();
                      }  
                  },  
                autoComplete: "off"
              }}
            />
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button color="info" onClick={handleUnlock} simple size="lg" disabled={loading != null} block>
              ACEPTAR
            </Button>            
          </CardFooter>
          {loading}
        </Card>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usuario: state.auth.usuario,
    nombreCorto: state.auth.nombreCorto,
    avatar: state.auth.avatar,
    unlockError: state.auth.error,
    pantallaBloqueada: state.auth.pantallaBloqueada,
    loading: state.auth.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    unlock: (credentials) => dispatch(unlock(credentials)),
    clearError: () => dispatch(clearError()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockScreenPage);