import React from "react";
import { connect } from "react-redux";
import { login, clearError } from "../../redux/actions/authActions"
import { verifyLength } from "../../utils/commonFunctions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Error from "@material-ui/icons/Error";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";

// assets
import defaultAvatar from "assets/img/login_avatar.png";

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

const useStyles = makeStyles(styles);

function LoginPage(props) {  
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [loginUser, setloginUser] = React.useState(props.usuario ? props.usuario : "");
  const [loginUserState, setloginUserState] = React.useState("");
  const [loginPassword, setloginPassword] = React.useState("");
  const [loginPasswordState, setloginPasswordState] = React.useState("");
  const [usuarioMatched, setUsuarioMatched] = React.useState(true);

  const { avatar, loading, loginError } = props;  

  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const handleLoginClick = () => {
    var bFormIsValid = true;
    if (loginUser === "") {
      setloginUserState("error");
      bFormIsValid = false;
    }
    if (loginPassword === "") {
      setloginPasswordState("error");
      bFormIsValid = false;
    }
    if(bFormIsValid){
      props.login({ usuario: loginUser, password: loginPassword });
    }        
  }

  const errorNotification = loginError ? (
    <Snackbar
      place="tc"
      color="danger"
      icon={Error}
      message={loginError}
      open
      closeNotification={() => props.clearError()}
      close
    />
  ) : null;

  return (
    <div className={classes.container}>
      {errorNotification}
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>          
          <form>          
            <Card profile
              className={classes.customCardClass + " " + classes[cardAnimaton]}>
              <CardAvatar profile className={classes.cardAvatar}>                
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={(avatar === "null" || !avatar || !usuarioMatched) ? defaultAvatar : avatar } alt="..." />
                </a>                
              </CardAvatar>              
              <CardBody>                              
                <CustomInput
                  success={loginUserState === "success"}
                  error={loginUserState === "error"}
                  labelText="Usuario"
                  id="usuario"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: loginUser,
                    onChange: event => {
                      if (verifyLength(event.target.value, 1)) {
                        setloginUserState("success");
                      } else {
                        setloginUserState("error");
                      }
                      setloginUser(event.target.value);
                      setUsuarioMatched(props.usuario === event.target.value);
                    },                    
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    autoComplete: "off"
                  }}
                />
                <CustomInput
                  success={loginPasswordState === "success"}
                  error={loginPasswordState === "error"}                
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    onChange: event => {
                      if (verifyLength(event.target.value, 1)) {
                        setloginPasswordState("success");
                      } else {
                        setloginPasswordState("error");
                      }
                      setloginPassword(event.target.value);
                    },     
                    onKeyDown: event => {
                      if (event.key === 'Enter') {
                        handleLoginClick();
                      }  
                    },                    
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="info" onClick={handleLoginClick} simple size="lg" disabled={loading != null} block>
                  ACEPTAR
                </Button>                             
              </CardFooter>
              {loading}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usuario: state.auth.usuario,
    avatar: state.auth.avatar,
    loginError: state.auth.error,
    loading: state.auth.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials)),
    clearError: () => dispatch(clearError()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);