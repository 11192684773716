import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment'; 
import Datetime from "react-datetime";
import { get, save, clearMessages } from "../../../redux/actions/censoObrasActions";
import { getListForSelect } from "../../../redux/actions/catalogoTipoObrasActions";
import { getListForSelect as getEstadosListForSelect } from "../../../redux/actions/catalogoEstadosActions";
import { getListForSelect as getMunicipiosListForSelect } from "../../../redux/actions/catalogoMunicipiosActions";
import { verifyDecimalNumber, verifyLength } from "../../../utils/commonFunctions";
import CensoObrasMap from "views/Censos/Obras/CensoObrasMap";
import CensoObrasPhoto from "views/Censos/Obras/CensoObrasPhoto";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Done from "@material-ui/icons/Done";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import SquareFoot from "@material-ui/icons/SquareFoot";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

function CensoObrasForm(props) {
    const classes = useStyles();
    const [estadosList, setEstadosList] = React.useState(null);
    const [municipiosList, setMunicipiosList] = React.useState(null);    
    const [show, setShow] = React.useState("form");    
    const [recordId] = React.useState(props.recordId);
    const [calle, setCalle] = React.useState("");
    const [calleState, setCalleState] = React.useState("");
    const [numeroExterior, setNumeroExterior] = React.useState("");
    const [numeroExteriorState, setNumeroExteriorState] = React.useState("");
    const [numeroInterior, setNumeroInterior] = React.useState("");    
    const [colonia, setColonia] = React.useState("");
    const [coloniaState, setColoniaState] = React.useState("");
    const [codigoPostal, setCodigoPostal] = React.useState("");
    const [codigoPostalState, setCodigoPostalState] = React.useState("");
    const [localidad, setLocalidad] = React.useState("");
    const [municipio, setMunicipio] = React.useState({value: "MAZATLÁN", label: "MAZATLÁN"});
    const [estado, setEstado] = React.useState({value: "SIN", label: "SINALOA"});
    const [metros, setMetros] = React.useState("");
    const [metrosState, setMetrosState] = React.useState("");
    const [tipoObra, setTipoObra] = React.useState("");
    const [tipoObraState, setTipoObraState] = React.useState("");
    const [clase, setClase] = React.useState("");
    const [claseState, setClaseState] = React.useState("");
    const [avance, setAvance] = React.useState("");
    const [avanceState, setAvanceState] = React.useState("");
    const [zona, setZona] = React.useState("");
    const [zonaState, setZonaState] = React.useState("");
    const [folioDeteccion, setFolioDeteccion] = React.useState("");
    const [fechaDeteccion, setFechaDeteccion] = React.useState(null);
    const [registroObra, setRegistroObra] = React.useState("");
    const [pooc, setPOOC] = React.useState("");
    const [fechaPOOC, setFechaPOOC] = React.useState(null);
    const [prto, setPRTO] = React.useState("");
    const [fechaPRTO, setFechaPRTO] = React.useState(null);
    const [regularizada, setRegularizada] = React.useState(false);    
    const [latitude, setLatitude] = React.useState(null);
    const [longitude, setLongitude] = React.useState(null);
    const [fotografia, setFotografia] = React.useState(null);

    const { loading, success, error, tipoObraList } = props;

    const claseList = [
        { value: 'PUBLICA', label: 'PUBLICA' },
        { value: 'PRIVADA', label: 'PRIVADA' },
    ];

    const zonaList = [
        { value: 'Zona 1', label: 'Zona 1' },
        { value: 'Zona 2', label: 'Zona 2' },
        { value: 'Zona 3', label: 'Zona 3' },
        { value: 'Zona 4', label: 'Zona 4' },
        { value: 'Zona 5', label: 'Zona 5' },
        { value: 'Zona Norte', label: 'Zona Norte' },
        { value: 'Zona Sur', label: 'Zona Sur' },
        { value: 'Obra Publica', label: 'Obra Publica' },
    ];

    React.useEffect(() => {       
        props.getListForSelect(); 
        props.getEstadosListForSelect();
        props.getMunicipiosListForSelect("SIN");        
    }, []);

    React.useEffect(() => {       
        if (recordId > 0) {
            props.getData(recordId);
        }
    }, [recordId]);

    // load estados list
    React.useEffect(() => {
        setEstadosList(props.estadosList);    
    }, [props.estadosList]);  

    // load municipios list
    React.useEffect(() => {
        setMunicipiosList(props.municipiosList);    
    }, [props.municipiosList]);   

    React.useEffect(() => {
        const formData = props.data;
        if (formData && recordId > 0) {
            setCalle(formData.Calle);
            setNumeroExterior(formData.NumeroExterior);
            setNumeroInterior(formData.NumeroInterior);
            setColonia(formData.Colonia);
            setCodigoPostal(formData.CodigoPostal);                       
            setEstado(formData.Estado !== "" ? {value: formData.ClaveEstado, label: formData.Estado} : "");
            props.getMunicipiosListForSelect(formData.ClaveEstado);
            setMunicipio(formData.Municipio !== "" ? {value: formData.Municipio, label: formData.Municipio} : "");
            setLocalidad(formData.Localidad);
            setMetros(formData.Metros);
            setTipoObra({ value: formData.IdTipoObra, label: formData.TipoObra });
            setClase({ value: formData.Clase, label: formData.Clase });
            setAvance(formData.PorcentajeAvance);
            setZona({ value: formData.Zona, label: formData.Zona });
            setFolioDeteccion(formData.FolioDeteccion);            
            setFechaDeteccion(formData.FechaDeteccion);
            setRegistroObra(formData.RegistroObra);
            setPOOC(formData.POOC);
            setFechaPOOC(formData.FechaPOOC);
            setPRTO(formData.PRTO);
            setFechaPRTO(formData.FechaPRTO);
            setRegularizada(formData.Regularizada == 1);      
            setLatitude(parseFloat(formData.Latitud));
            setLongitude(parseFloat(formData.Longitud));        
            setFotografia(formData.Fotografia);            
        }
    }, [props.data]);

    const clean = () => {
        setEstadosList(null);
        setMunicipiosList(null);
        setCalle("");
        setCalleState("");
        setNumeroExterior("");
        setNumeroExteriorState("");
        setNumeroInterior("");
        setColonia("");
        setColoniaState("");
        setCodigoPostal("");
        setCodigoPostalState("");
        setLocalidad("");
        setMunicipio({value: "MAZATLÁN", label: "MAZATLÁN"});
        setEstado({value: "SIN", label: "SINALOA"});
        setMetros("");
        setMetrosState("");
        setTipoObra("");
        setTipoObraState("");
        setClase("");
        setClaseState("");
        setAvance("");
        setAvanceState("");
        setZona("");
        setZonaState("");
        setFolioDeteccion("");
        setFechaDeteccion(null);
        setRegistroObra("");
        setPOOC("");
        setFechaPOOC(null);
        setPRTO("");
        setFechaPRTO(null);
        setRegularizada(false);
        setLatitude(null);
        setLongitude(null);
        setFotografia(null);
    }

    const returnClick = () => {
        props.returnToList();
    }
    
    const saveClick = () => {
        var bFormIsValid = true;
        if (calle === "") {
            setCalleState("error");
            bFormIsValid = false;
        }
        if (numeroExterior === "") {
            setNumeroExteriorState("error");
            bFormIsValid = false;
        }        
        if (colonia === "") {
            setColoniaState("error");
            bFormIsValid = false;
        }
        if (codigoPostal === "") {
            setCodigoPostalState("error");
            bFormIsValid = false;
        }                        
        if (metros === "") {
            setMetrosState("error");
            bFormIsValid = false;
        }        
        if (metros === "") {
            setMetrosState("error");
            bFormIsValid = false;
        }        
        if (tipoObra === "") {
            setTipoObraState("error");
            bFormIsValid = false;
        }
        if (clase === "") {
            setClaseState("error");
            bFormIsValid = false;
        }
        if (avance === "") {
            setAvanceState("error");
            bFormIsValid = false;
        }
        if (zona === "") {
            setZonaState("error");
            bFormIsValid = false;
        }
        if(latitude === null || longitude === null){
            bFormIsValid = false;
        }
        if(fotografia ===  null || fotografia === ""){
            bFormIsValid = false;
        }
        if (bFormIsValid) {
            props.save(recordId, calle, numeroExterior, numeroInterior, colonia, codigoPostal, localidad, municipio.value, estado.label, metros, tipoObra.value, clase.value, avance, zona.value, folioDeteccion, fechaDeteccion, registroObra, pooc, fechaPOOC, prto, fechaPRTO, regularizada, latitude, longitude, fotografia);
        }
    };
    
    const locationClick = () => {
        setShow("map")
    };

    const photoClick = () => {
        setShow("photo")
    };

    const hideSuccessAlert = () => {
        clean();
        props.clearMessages();        
        props.returnToList();
    };

    const hideErrorAlert = () => {
        props.clearMessages();
    };

    const successMessage = !success ? null : (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Correcto!"
          onConfirm={() => hideSuccessAlert(true)}
          onCancel={() => hideSuccessAlert(true)}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.success}
        </SweetAlert>
      );
    
      const errorMessage = !error ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => hideErrorAlert()}
          onCancel={() => hideErrorAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.error}
        </SweetAlert>
      );      

    const handleEstadoChange = (selectedOption) => {
        setMunicipiosList(null);
        setMunicipio("");
        
        setEstado(selectedOption);
        if(selectedOption){
            props.getMunicipiosListForSelect(selectedOption.value);
        }
    }  
    
    const handleMunicipioChange = (selectedOption) => {
        setMunicipio(selectedOption);
    }  

    const handleTipoObraChange = (selectedOption) => {
        setTipoObra(selectedOption);
    }   

    const handleClaseChange = (selectedOption) => {
        setClase(selectedOption);
    }       

    const handleZonaChange = (selectedOption) => {
        setZona(selectedOption);
    }

    const estadoStyles = {
        control: styles => ({ ...styles, marginTop: '21px' }),        
    };
    
    const municipioStyles = {
        control: styles => ({ ...styles, marginTop: '21px' }),        
    };    

    const tipoObraStyles = {
        control: styles => ({ ...styles, marginTop: '21px' }),
        placeholder: styles => ({ ...styles, color: tipoObraState === "error" ? '#f44336' : tipoObra === "success" ? '#4caf50' : '#AAAAAA' }),
    };
    
    const claseStyles = {
        control: styles => ({ ...styles, marginTop: '21px' }),
        placeholder: styles => ({ ...styles, color: claseState === "error" ? '#f44336' : claseState === "success" ? '#4caf50' : '#AAAAAA' }),
    };

    const zonaStyles = {
        control: styles => ({ ...styles, marginTop: '21px' }),
        placeholder: styles => ({ ...styles, color: zonaState === "error" ? '#f44336' : zonaState === "success" ? '#4caf50' : '#AAAAAA' }),
    };

    const handleFechaDeteccionChange = date => {
        setFechaDeteccion(date);
    };

    const handleFechaPOOCChange = date => {
        setFechaPOOC(date);
    };

    const handleFechaPRTOChange = date => {
        setFechaPRTO(date);
    };    
    
    const validDateTimes = (current) => {
        const today = Datetime.moment();
        return current.isBefore(today);
    }
    
    const returnToForm = () => {
        setShow("form")
        props.scrollToTop();    
    }

    const onLocationSelected = (latitude, longitude) => {
        setLatitude(latitude);
        setLongitude(longitude);
    }

    const onImageSelected = image => {
        setFotografia(image);
    }

    const form = (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Select
                                    isClearable
                                    isDisabled={estadosList == null}
                                    isLoading={estadosList == null}                                    
                                    options={estadosList}
                                    onChange={handleEstadoChange}
                                    placeholder="Seleccionar Estado"
                                    value={estado}
                                    styles={estadoStyles}
                                />
                            </GridItem>    
                            <GridItem xs={12} sm={12} md={4}>
                            <Select 
                                options={municipiosList} 
                                isClearable
                                isDisabled={municipiosList == null}
                                isLoading={estado != "" && municipiosList == null}
                                onChange={handleMunicipioChange}    
                                styles={municipioStyles}                       
                                placeholder="Seleccionar Municipio"
                                value={municipio}
                                />
                            </GridItem>                                                
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    labelText="Localidad"
                                    id="localidad"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            setLocalidad(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: localidad
                                    }}
                                />
                            </GridItem>
                        </GridContainer>                        
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    success={codigoPostalState === "success"}
                                    error={codigoPostalState === "error"}                                                                
                                    labelText="Codigo postal *"
                                    id="codigoPostal"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (verifyLength(event.target.value, 1)) {
                                                setCodigoPostalState("");
                                            } else {
                                                setCodigoPostalState("error");
                                            }                                              
                                            setCodigoPostal(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: codigoPostal
                                    }}
                                />
                            </GridItem>                            
                            <GridItem xs={12} sm={12} md={6}>                                
                                <CustomInput
                                    success={coloniaState === "success"}
                                    error={coloniaState === "error"}                                                                
                                    labelText="Colonia *"
                                    id="colonia"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (verifyLength(event.target.value, 1)) {
                                                setColoniaState("");
                                            } else {
                                                setColoniaState("error");
                                            }                                              
                                            setColonia(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: colonia
                                    }}
                                />
                            </GridItem>
                        </GridContainer>                        
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    success={calleState === "success"}
                                    error={calleState === "error"}                                
                                    labelText="Calle *"
                                    id="calle"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (verifyLength(event.target.value, 1)) {
                                                setCalleState("");
                                            } else {
                                                setCalleState("error");
                                            }                                            
                                            setCalle(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: calle
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    success={numeroExteriorState === "success"}
                                    error={numeroExteriorState === "error"}                                
                                    labelText="Numero exterior *"
                                    id="numeroExterior"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (verifyLength(event.target.value, 1)) {
                                                setNumeroExteriorState("");
                                            } else {
                                                setNumeroExteriorState("error");
                                            }                                            
                                            setNumeroExterior(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: numeroExterior
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    labelText="Numero interior"
                                    id="numeroInterior"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            setNumeroInterior(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: numeroInterior
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    labelText="Folio detección"
                                    id="folioDeteccion"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            setFolioDeteccion(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: folioDeteccion
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <InputLabel className={classes.label}>Fecha Detección</InputLabel>
                                <br />
                                <FormControl fullWidth style={{ marginTop: "3px" }}>
                                    <Datetime
                                        value={fechaDeteccion !== null ? moment(fechaDeteccion).format("DD/MM/YYYY"): null}
                                        locale="es"
                                        closeOnSelect
                                        timeFormat={false}
                                        inputProps={{
                                            placeholder: "Seleccione",
                                            readOnly: true,
                                        }}
                                        onChange={handleFechaDeteccionChange}
                                        isValidDate={validDateTimes}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    labelText="Registro de obra"
                                    id="registroObra"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            setRegistroObra(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: registroObra
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    labelText="POOC"
                                    id="pooc"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            setPOOC(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: pooc
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <InputLabel className={classes.label}>Fecha POOC</InputLabel>
                                <br />
                                <FormControl fullWidth style={{ marginTop: "3px" }}>
                                    <Datetime
                                        value={fechaPOOC !== null ? moment(fechaPOOC).format("DD/MM/YYYY") : null}
                                        locale="es"
                                        closeOnSelect
                                        timeFormat={false}
                                        inputProps={{
                                            placeholder: "Seleccione",
                                            readOnly: true,
                                        }}
                                        onChange={handleFechaPOOCChange}
                                        isValidDate={validDateTimes}
                                    />
                                </FormControl>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    labelText="PRTO"
                                    id="prto"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            setPRTO(event.target.value);
                                        },
                                        autoComplete: "off",
                                        value: prto
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <InputLabel className={classes.label}>Fecha PRTO</InputLabel>
                                <br />
                                <FormControl fullWidth style={{ marginTop: "3px" }}>
                                    <Datetime
                                        value={fechaPRTO !== null ? moment(fechaPRTO).format("DD/MM/YYYY") : null}
                                        locale="es"
                                        closeOnSelect
                                        timeFormat={false}
                                        inputProps={{
                                            placeholder: "Seleccione",
                                            readOnly: true,
                                        }}
                                        onChange={handleFechaPRTOChange}
                                        isValidDate={validDateTimes}
                                    />
                                </FormControl>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    success={metrosState === "success"}
                                    error={metrosState === "error"}
                                    labelText="Metros *"
                                    id="metros"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (verifyDecimalNumber(event.target.value, 1)) {
                                                setMetrosState("");
                                            } else {
                                                setMetrosState("error");
                                            }
                                            setMetros(event.target.value);
                                        },
                                        type: "number",
                                        placeholder: '0.00',
                                        autoComplete: "off",
                                        value: metros,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SquareFoot className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <Select
                                    isClearable
                                    options={tipoObraList}
                                    onChange={handleTipoObraChange}
                                    placeholder="Tipo de obra *"
                                    value={tipoObra}
                                    styles={tipoObraStyles}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <Select
                                    isClearable
                                    options={claseList}
                                    onChange={handleClaseChange}
                                    placeholder="Clase *"
                                    value={clase}
                                    styles={claseStyles}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <CustomInput
                                    success={avanceState === "success"}
                                    error={avanceState === "error"}
                                    labelText="Avance *"
                                    id="avance"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: event => {
                                            if (verifyDecimalNumber(event.target.value)) {
                                                setAvanceState("");
                                            } else {
                                                setAvanceState("error");
                                            }
                                            setAvance(event.target.value);
                                        },
                                        type: "number",
                                        placeholder: '0.00',
                                        autoComplete: "off",
                                        value: avance,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span style={{ fontWeight: "Bold" }}>%</span>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <Select
                                    isClearable
                                    options={zonaList}
                                    onChange={handleZonaChange}
                                    placeholder="Zona *"
                                    value={zona}
                                    styles={zonaStyles}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            tabIndex={-1}
                                            onClick={(event) => setRegularizada(event.target.checked)}
                                            checked={regularizada}
                                            checkedIcon={
                                                <Check className={classes.checkedIcon} />
                                            }
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                    }}
                                    label="Regularizada"
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <Button color={latitude === null || longitude === null ? "danger" : "success"} size="sm" onClick={locationClick}>
                                    {latitude === null || longitude === null ? <Close className={classes.icons} /> : <Done className={classes.icons} />}
                                     Ubicación
                                </Button>
                                <Button color={fotografia === null || fotografia === "" ? "danger" : "success"} size="sm" onClick={photoClick}>
                                    {fotografia === null || fotografia === "" ? <Close className={classes.icons} /> : <Done className={classes.icons} />}
                                     Fotografía
                                </Button>                                
                            </GridItem>
                        </GridContainer>                                                       
                        <div className={classes.formCategory} style={{ textAlign: "right" }}>
                            <small>*</small> Campos obligatorios
                        </div>
                        <Button color="info" onClick={saveClick} className={classes.registerButton} disabled={loading != null}>
                            Guardar
                        </Button>
                        <Button color="warning" onClick={returnClick} className={classes.registerButton} disabled={loading != null}>
                            Regresar
                        </Button>
                    </GridItem>
                </GridContainer>
                {loading}
            </GridItem>
        </GridContainer>
    );

    const scrollToTop = () => {
        props.scrollToTop();
    }

    const map = <CensoObrasMap latitude={latitude} longitude={longitude} onReturn={returnToForm} onLocationSelected={onLocationSelected} scrollToTop={scrollToTop}/>;
    const photo = <CensoObrasPhoto
                    addButtonProps={{
                    color: "success"
                    }}
                    removeButtonProps={{
                    color: "danger"
                    }}
                    returnToForm={returnToForm} 
                    imageSelected={onImageSelected} 
                    scrollToTop={scrollToTop}
                    image={fotografia}
                />

    return (
        <div>
            {successMessage}
            {errorMessage}   
            {show === "form" ? form : show === "map"  ? map : show === "photo" ? photo : null}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        data: state.censoObras.data,
        success: state.censoObras.successMessage,
        error: state.censoObras.error,
        loading: state.censoObras.loading,
        tipoObraList: state.catalogoTipoObras.selectList,
        estadosList: state.catalogoEstados.selectList,
        municipiosList: state.catalogoMunicipios.selectList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (id) => dispatch(get(id)),
        save: (id, calle, numeroExterior, numeroInterior, colonia, codigoPostal, localidad, municipio, estado, metros, idTipoObra, clase, porcentajeAvance, zona, folioDeteccion, fechaDeteccion, registroObra, pooc, fechaPOOC, prto, fechaPRTO, regularizada, latitud, longitud, fotografia) => dispatch(save(id, calle, numeroExterior, numeroInterior, colonia, codigoPostal, localidad, municipio, estado, metros, idTipoObra, clase, porcentajeAvance, zona, folioDeteccion, fechaDeteccion, registroObra, pooc, fechaPOOC, prto, fechaPRTO, regularizada, latitud, longitud, fotografia)),
        clearMessages: () => dispatch(clearMessages()),
        getListForSelect: () => dispatch(getListForSelect()),
        getEstadosListForSelect: () => dispatch(getEstadosListForSelect()),
        getMunicipiosListForSelect: (claveEstado) => dispatch(getMunicipiosListForSelect(claveEstado)),        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CensoObrasForm);