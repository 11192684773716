import axios from 'axios';
import store from './redux/store'

// Create an axios instance
let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {    
    // Add access token before send the request
    const token = store.getState().auth.accessToken;
    if(token){        
        //config.headers['x-access-token'] = `Bearer ${token}`;
        config.headers['x-access-token'] = token;
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});

export default axiosInstance;