import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { getListForTable, updateStatus, clearMessages } from "redux/actions/catalogoEmpleadosActions";
import EmpleadosForm from "views/Catalogos/Empleados/EmpleadosForm";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Error from "@material-ui/icons/Error";
import Add from "@material-ui/icons/Add";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/recordsListStyle.js";

const useStyles = makeStyles(styles);

function EmpleadosList(props) {
    const [showList, setShowList] = React.useState(true);
    const [recordId, setRecordId] = React.useState(0);
    const [listData, setListData] = React.useState(null);
    const [confirmDialog, setConfirmDialog] = React.useState(null);

    const { loading, error } = props;    

    // component did mount
    React.useEffect(() => {
        props.getList();
    }, []);

    // when prop.list change
    React.useEffect(() => {
        if (props.list) {
            setListData(props.list.map((prop, key) => {
                return {
                    id: prop.Id,
                    nombre: prop.Nombre,
                    zona: prop.Zona,
                    acciones: (
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    const row = props.list.find(r => r.Id === prop.Id);
                                    editRecordClick(row.Id);
                                }}
                                color="info"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    const row = props.list.find(r => r.Id === prop.Id);
                                    deleteRecordClick(row.Id);
                                }}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                };
            }));
        }
    }, [props.list]);

    // when prop.reloadList change
    React.useEffect(() => {
        if(props.reloadList){
            setConfirmDialog(null);
            props.getList();
        }        
    }, [props.reloadList]);

    const editRecordClick = id => {
        setRecordId(id);
        setShowList(false);
    };

    const deleteRecordClick = id => {
        setConfirmDialog(
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Estas seguro de eliminar el registro?"
              onConfirm={() => props.updateStatus(id, 0)}
              onCancel={() => setConfirmDialog(null)}
              confirmBtnCssClass={classes.button + " " + classes.success}
              cancelBtnCssClass={classes.button + " " + classes.danger}
              confirmBtnText="Aceptar"
              cancelBtnText="Cancelar"
              showCancel
            />
          );                
    };

    const reactTable = listData ? (
        <ReactTable
            columns={[
                {
                    Header: "Nombre",
                    accessor: "nombre"
                },
                {
                    Header: "Zona",
                    accessor: "zona"
                },
                {
                    Header: "Acciones",
                    accessor: "acciones"
                }
            ]}
            data={listData}
        />
    ) : loading;

    const addButtonClick = () => {
        setRecordId(0);
        setShowList(false);
    }

    const returnToList = () => {
        setShowList(true);
        props.scrollToTop();    
    }

    const form = <EmpleadosForm recordId={recordId} returnToList={returnToList}/>;    

    const errorNotification = error ? (
        <Snackbar
          place="tc"
          color="danger"
          icon={Error}
          message={error}
          open
          closeNotification={() => props.clearMessages()}
          close
        />
      ) : null;    

    const classes = useStyles();    

    return (
        <GridContainer>
            {confirmDialog}
            {errorNotification}         
            <GridItem xs={12} style={{ textAlign: "right" }}>
                <Button color="info" size="sm" className={classes.marginRight} onClick={addButtonClick}>
                    <Add className={classes.icons} /> Agregar
                </Button>                
            </GridItem>             
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <AssignmentInd />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{showList ? "Listado de registros" : "Captura de información"}</h4>
                    </CardHeader>
                    <CardBody>
                        {showList ? reactTable : form}
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        list: state.catalogoEmpleados.genericList,
        reloadList: state.catalogoEmpleados.reloadList,
        error: state.catalogoEmpleados.listError,
        loading: state.catalogoEmpleados.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getList: () => dispatch(getListForTable()),
        updateStatus: (id, status) => dispatch(updateStatus(id, status)),
        clearMessages: () => dispatch(clearMessages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmpleadosList);