import http from '../../http';

export const login = (credentials) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        const parameters = JSON.stringify({
            usuario: credentials.usuario,
            password: credentials.password,
        });
    
        dispatch({ type: 'AUTH_LOADING' });
    
        http.post("auth", parameters, config)
            .then(response => {
                dispatch({
                    type: "LOGIN_CORRECTO", payload: {
                        accessToken: response.data.accessToken,
                        idUsuario: response.data.idUsuario,
                        usuario: credentials.usuario,
                        nombreCorto: response.data.nombreCorto,                    
                        perfil: response.data.perfil,
                        zona: response.data.zona,
                        avatar: response.data.avatar,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'LOGIN_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const logout = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'LOGOUT_CORRECTO' });
    }
}

export const lock = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'BLOQUEO_CORRECTO' });
    }
}

export const unlock = (credentials) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        const parameters = JSON.stringify({
            usuario: credentials.usuario,
            password: credentials.password,
        });
    
        dispatch({ type: 'AUTH_LOADING' });

        http.post("auth", parameters, config)
            .then(response => {
                dispatch({
                    type: "DEBLOQUEO_CORRECTO", payload: {
                        accessToken: response.data.accessToken,
                        idUsuario: response.data.idUsuario,
                        usuario: credentials.usuario,
                        nombreCorto: response.data.nombreCorto,                    
                        perfil: response.data.perfil,
                        zona: response.data.zona,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'DEBLOQUEO_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }        
}

export const clearError = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'LIMPIAR_ERROR' });
    }
}