import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Carousel, { Modal, ModalGateway } from 'react-images';
import { getListForTable, updateStatus, clearMessages, getPhoto, getLocation } from "redux/actions/censoObrasActions";
import CensoObrasForm from "views/Censos/Obras/CensoObrasForm";
import CensoObrasMap from "views/Censos/Obras/CensoObrasMap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Error from "@material-ui/icons/Error";
import Add from "@material-ui/icons/Add";
import Place from "@material-ui/icons/Place";
import CameraAlt from "@material-ui/icons/CameraAlt";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/recordsListStyle.js";

const useStyles = makeStyles(styles);

function ListadoCensoObras(props) {
    const [show, setShow] = React.useState("list");    
    const [recordId, setRecordId] = React.useState(0);
    const [listData, setListData] = React.useState(null);
    const [confirmDialog, setConfirmDialog] = React.useState(null);
    const [showPhoto, setShowPhoto] = React.useState(false);
    const [images, setImages] = React.useState([]);    
    const [latitude, setLatitude] = React.useState(null);
    const [longitude, setLongitude] = React.useState(null);

    const { loading, error } = props;   
    
    const toggleModal = () => {
        setShowPhoto(!showPhoto);        
    }    

    // component did mount
    React.useEffect(() => {
        props.getList();
    }, []);

    // when prop.list change
    React.useEffect(() => {
        if (props.list) {
            setListData(props.list.map((prop, key) => {
                return {
                    id: prop.Id,
                    domicilio: prop.Domicilio,
                    acciones: (
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    const row = props.list.find(r => r.Id === prop.Id);
                                    editRecordClick(row.Id);
                                }}
                                color="info"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}                            
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    const row = props.list.find(r => r.Id === prop.Id);
                                    mapRecordClick(row.Id);
                                }}
                                color="info"
                                className="edit"
                            >
                                <Place />
                            </Button>{" "}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    const row = props.list.find(r => r.Id === prop.Id);
                                    imageRecordClick(row.Id);
                                }}
                                color="info"
                                className="edit"
                            >
                                <CameraAlt />
                            </Button>{" "}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    const row = props.list.find(r => r.Id === prop.Id);
                                    deleteRecordClick(row.Id);
                                }}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                };
            }));
        }
    }, [props.list]);

    // when prop.reloadList change
    React.useEffect(() => {
        if(props.reloadList){
            setConfirmDialog(null);
            props.getList();
        }        
    }, [props.reloadList]);

    const editRecordClick = id => {
        setRecordId(id);
        setShow("form");
    };

    const mapRecordClick = id => {      
        props.getLocation(id);
    };

    const imageRecordClick = id => {
        props.getPhoto(id);
    };

    React.useEffect(() => {
        const photo = props.photo;
        if (photo) {
            setImages([{ src: photo.Fotografia }]);
            setShowPhoto(true);            
        }
    }, [props.photo]);    

    React.useEffect(() => {
        const locationData = props.location;
        if (locationData) {
            setLatitude(parseFloat(locationData.Latitud));
            setLongitude(parseFloat(locationData.Longitud));
            setShow("map");
        }
    }, [props.location]);

    const deleteRecordClick = id => {
        setConfirmDialog(
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Estas seguro de eliminar el registro?"
              onConfirm={() => props.updateStatus(id, 0)}
              onCancel={() => setConfirmDialog(null)}
              confirmBtnCssClass={classes.button + " " + classes.success}
              cancelBtnCssClass={classes.button + " " + classes.danger}
              confirmBtnText="Aceptar"
              cancelBtnText="Cancelar"
              showCancel
            />
          );                
    };

    const reactTable = listData ? (
        <ReactTable
            columns={[
                {
                    Header: "Domicilio",
                    accessor: "domicilio"
                },
                {
                    Header: "Acciones",
                    accessor: "acciones"
                }
            ]}
            data={listData}
        />
    ) : loading;

    const addButtonClick = () => {
        setRecordId(0);
        setShow("form");
    }

    const returnToList = () => {
        setShow("list");
        props.scrollToTop();
    }

    const scrollToTop = () => {
        props.scrollToTop();
    }

    const form = <CensoObrasForm recordId={recordId} returnToList={returnToList} scrollToTop={scrollToTop}/>;
    const map = <CensoObrasMap latitude={latitude} longitude={longitude} onReturn={returnToList} scrollToTop={scrollToTop} isReadOnly={true} />;    

    const errorNotification = error ? (
        <Snackbar
          place="tc"
          color="danger"
          icon={Error}
          message={error}
          open
          closeNotification={() => props.clearMessages()}
          close
        />
      ) : null;    

    const classes = useStyles();    

    return (
        <GridContainer>
            {confirmDialog}
            {errorNotification}         
            <GridItem xs={12} style={{ textAlign: "right" }}>
                <Button color="info" size="sm" className={classes.marginRight} onClick={addButtonClick}>
                    <Add className={classes.icons} /> Agregar
                </Button>                
            </GridItem>             
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <AssignmentInd />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{show === "list" ? "Listado de registros" : show === "form" ? "Captura de información" : show === "map" ? "Ubicación geografica" : ""}</h4>
                    </CardHeader>
                    <CardBody>
                        {show === "list" ? reactTable : show === "form" ? form : show === "map" ? map : null}
                        <ModalGateway>
                            {showPhoto ? (
                            <Modal onClose={toggleModal}>
                                <Carousel views={images} />
                            </Modal>
                            ) : null}
                        </ModalGateway>                        
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        list: state.censoObras.genericList,
        reloadList: state.censoObras.reloadList,
        error: state.censoObras.listError,
        loading: state.censoObras.loading,
        photoAndLocation: state.censoObras.photoAndLocation,
        photo: state.censoObras.photo,
        location: state.censoObras.location,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getList: () => dispatch(getListForTable()),
        updateStatus: (id, status) => dispatch(updateStatus(id, status)),
        clearMessages: () => dispatch(clearMessages()),
        getPhoto: (id) => dispatch(getPhoto(id)),
        getLocation: (id) => dispatch(getLocation(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListadoCensoObras);