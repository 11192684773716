import http from '../../http';
import store from '../../redux/store';

export const getListForTable = () => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
    
        dispatch({ type: 'CATALOGO_TIPO_OBRAS_LOADING' });
        
        http.get(`catalogotipoobras/listado`, config)        
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_TIPO_OBRAS_LIST", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_TIPO_OBRAS_LIST_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            }); 
    }
}

export const getListForSelect = () => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
      
        http.get(`catalogotipoobras/listadoselect`, config)        
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_TIPO_OBRAS_LIST_SELECT", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_TIPO_OBRAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            }); 
    }
}

export const get = (id) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
   
        dispatch({ type: 'CATALOGO_TIPO_OBRAS_LOADING' });
       
        http.get(`catalogotipoobras/${id}`, config)
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_TIPO_OBRAS_GET", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_TIPO_OBRAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const updateStatus = (id, status) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, status });   
    
        dispatch({ type: 'CATALOGO_TIPO_OBRAS_LOADING' });
        
        http.put(`catalogotipoobras/updatestatus`, parameters, config)
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_TIPO_OBRAS_STATUS_ACTUALIZADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_TIPO_OBRAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const save = (id, descripcion) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, descripcion, idEmpleado: store.getState().auth.idUsuario });
        
        dispatch({ type: 'CATALOGO_TIPO_OBRAS_LOADING' });
        
        http.post(`catalogotipoobras`, parameters, config)
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_TIPO_OBRAS_GUARDADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_TIPO_OBRAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const clearMessages = () => {
    return (dispatch, getState) => {
        dispatch({type: "CATALOGO_TIPO_OBRAS_LIMPIAR_MENSAJES"});
    }
}