import React from "react";
// core components
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

const initState = {
    selectList: null,
    error: null,
    loading: null,
}

const catalogoMunicipiosReducer = (state = initState, action) => {
    
    switch (action.type) {
        case "CATALOGO_MUNICIPIOS_LIST_SELECT":
            return {
                ...state,
                selectList: action.payload.data,
                loading: null,
                error: null,
            }       
        case "CATALOGO_MUNICIPIOS_ERROR":
            return {
                ...state,
                error: action.payload.error,   
                loading: null,                     
            }
        case "CATALOGO_MUNICIPIOS_LIMPIAR_MENSAJES": {
            return {
                ...state,
                error: null,
            }
        }            
        case "CATALOGO_MUNICIPIOS_LOADING": {
            return {
                ...state,
                loading: (<CustomLinearProgress color="info" />),
            }
        }                        
        default:
            return state;
    }
}

export default catalogoMunicipiosReducer;