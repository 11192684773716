import React from "react";
import Avatar from "react-avatar-edit";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { get, updateNombreCorto, updatePassword, updateAvatar, clearMessages } from "../../redux/actions/catalogoEmpleadosActions";
import { verifyLength } from "../../utils/commonFunctions";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import Face from "@material-ui/icons/Face";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Error from "@material-ui/icons/Error";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import NavPills from "components/NavPills/NavPills.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

const useStyles = makeStyles(styles);

function MiCuenta(props) {
    const classes = useStyles();

    const [nombre, setNombre] = React.useState("");
    const [perfil, setPerfil] = React.useState("");
    const [zona, setZona] = React.useState("");
    const [nombreCorto, setNombreCorto] = React.useState("");
    const [nombreCortoState, setNombreCortoState] = React.useState("");
    const [passwordActual, setPasswordActual] = React.useState("");
    const [passwordActualState, setPasswordActualState] = React.useState("");
    const [showPasswordActual, setShowPasswordActual] = React.useState(false);
    const [nuevoPassword, setNuevoPassword] = React.useState("");
    const [nuevoPasswordState, setNuevoPasswordState] = React.useState("");
    const [showNuevoPassword, setShowNuevoPassword] = React.useState(false);
    const [confirmarPassword, setConfirmarPassword] = React.useState("");
    const [confirmarPasswordState, setConfirmarPasswordState] = React.useState("");
    const [showConfirmarPassword, setShowConfirmarPassword] = React.useState(false);
    const [previewAvatar, setPreviewAvatar] = React.useState(null);
    const [avatarErrror, setAvatarError] = React.useState(null);

    const { idUsuario, usuario, loading, success, error } = props;

    React.useEffect(() => {
        if(idUsuario){
            props.getData(idUsuario);
        }        
    }, [idUsuario]);

    React.useEffect(() => {
        const formData = props.data;
        if(formData){
            setNombre(`${formData.Nombre} ${formData.ApellidoPaterno} ${formData.ApellidoMaterno}`);
            setPerfil(formData.Perfil);
            setZona(formData.Zona);
            setNombreCorto(formData.NombreCorto);
            setPreviewAvatar(formData.Avatar);
        }        
    }, [props.data]);

    const onCloseAvatar = () => {
        setPreviewAvatar(null);
    }
    
    const onCropAvatar = preview => {
        setPreviewAvatar(preview);
    }
    
    const onBeforeFileLoadAvatar = elem => {
        setAvatarError(null);        
        if (elem.target.files[0].size > 204800) { //200 kb 
            elem.target.value = "";
            setAvatarError(<Snackbar
                place="tc"
                color="danger"
                icon={Error}
                message="El archivo no debe superar los 200kb"
                open
                closeNotification={() => setAvatarError(null)}
                close
              />);
        };
    }

    const updateInfoClick = () => {
        var bFormIsValid = true;    
        if (nombreCorto === "") {
          setNombreCortoState("error");
          bFormIsValid = false;
        }
        if(bFormIsValid){
          props.updateNombreCorto(idUsuario, nombreCorto);
        } 
    };

    const updatePasswordClick = () => {
        var bFormIsValid = true;    
        if (passwordActual === "") {
          setPasswordActualState("error");
          bFormIsValid = false;
        }
        if (nuevoPassword === "") {
            setNuevoPasswordState("error");
            bFormIsValid = false;
        }        
        if (confirmarPassword === "") {
            setConfirmarPasswordState("error");
            bFormIsValid = false;
        }
        
        if(bFormIsValid){
          props.updatePassword(idUsuario, usuario, passwordActual, nuevoPassword, confirmarPassword);
        } 
    };

    const updateAvatarClick = () => {
        var bFormIsValid = true;    
        if (!previewAvatar) {
          bFormIsValid = false;
        }        
        if(bFormIsValid){
          props.updateAvatar(idUsuario, previewAvatar);
        }       
    }

    const removeAvatarClick = () => {
        setPreviewAvatar(null);
        props.updateAvatar(idUsuario, null);      
    }    
    
    const hideAlert = () => {
        props.clearMessages();
    };

    const visibilityButtonClick = (field) => {
        switch(field){
            case "passwordActual":
                setShowPasswordActual(!showPasswordActual);
                break;
            case "nuevoPassword":
                setShowNuevoPassword(!showNuevoPassword);
                break;
            case "confirmarPassword":
                setShowConfirmarPassword(!showConfirmarPassword);
                break;                                
        }        
    }

    const successMessage = !success ? null : (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Correcto!"
          onConfirm={() => hideAlert(true)}
          onCancel={() => hideAlert(true)}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.success}
        </SweetAlert>
      );
    
      const errorMessage = !error ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.error}
        </SweetAlert>
      ); 

    return (
        <div>
            {successMessage}
            {errorMessage}    
            {avatarErrror}        
            <GridContainer>
                <GridItem xs={12} sm={12} md={1}></GridItem>
                <GridItem xs={12} sm={12} md={10}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <PermIdentity />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Configure su perfil
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <NavPills
                                        color="info"
                                        horizontal={{
                                        tabsGrid: { xs: 12, sm: 12, md: 3 },
                                        contentGrid: { xs: 12, sm: 12, md: 9 }
                                        }}
                                        tabs={[
                                        {
                                            tabButton: "Informacion General",
                                            tabIcon: PermContactCalendar,
                                            tabContent: (
                                            <>  
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <CustomInput
                                                            labelText="Nombre"
                                                            id="nombreEmpleado"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: true,
                                                                value: nombre
                                                            }}
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            labelText="Perfil"
                                                            id="perfil"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: true,
                                                                value: perfil
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            labelText="Zona"
                                                            id="zona"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: true,
                                                                value: zona
                                                            }}
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <CustomInput
                                                                success={nombreCortoState === "success"}
                                                                error={nombreCortoState === "error"}
                                                                labelText="Nombre corto"
                                                                id="nombreCorto"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    onChange: event => {
                                                                        if (verifyLength(event.target.value, 1)) {
                                                                        setNombreCortoState("success");
                                                                        } else {
                                                                            setNombreCortoState("error");
                                                                        }
                                                                        setNombreCorto(event.target.value);
                                                                    },
                                                                    autoComplete: "off",
                                                                    value: nombreCorto
                                                                }}                                                                
                                                            />
                                                        </GridItem>
                                                </GridContainer>
                                                <Button color="info" className={classes.updateProfileButton} disabled={loading != null} onClick={updateInfoClick}>
                                                    Actualizar información
                                                </Button>
                                                <Clearfix />                                                
                                            </>
                                            )
                                        },
                                        {
                                            tabButton: "Password",
                                            tabIcon: LockOutlined,
                                            tabContent: (
                                                <>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput                                                        
                                                            labelText="Usuario"
                                                            id="usuario"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                disabled: true,
                                                                value: usuario
                                                            }}
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            success={passwordActualState === "success"}
                                                            error={passwordActualState === "error"}
                                                            labelText="Password actual"
                                                            id="passwordActual"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                onChange: event => {
                                                                    if (verifyLength(event.target.value, 1)) {
                                                                        setPasswordActualState("success");
                                                                    } else {
                                                                        setPasswordActualState("error");
                                                                    }
                                                                    setPasswordActual(event.target.value);
                                                                },
                                                                autoComplete: "off",
                                                                value: passwordActual,
                                                                type: showPasswordActual ? 'text' : 'password',
                                                                endAdornment: (
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => visibilityButtonClick("passwordActual")}
                                                                    >
                                                                        {showPasswordActual ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                )                                                                   
                                                            }}                                                             
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            success={nuevoPasswordState === "success"}
                                                            error={nuevoPasswordState === "error"}
                                                            labelText="Nuevo password"
                                                            id="nuevoPassword"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                onChange: event => {
                                                                    if (verifyLength(event.target.value, 1)) {
                                                                        setNuevoPasswordState("success");
                                                                    } else {
                                                                        setNuevoPasswordState("error");
                                                                    }
                                                                    setNuevoPassword(event.target.value);
                                                                },
                                                                autoComplete: "off",
                                                                value: nuevoPassword,
                                                                type: showNuevoPassword ? 'text' : 'password',
                                                                endAdornment: (
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => visibilityButtonClick("nuevoPassword")}
                                                                    >
                                                                        {showNuevoPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                )                                                                
                                                            }}                                                             
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            success={confirmarPasswordState === "success"}
                                                            error={confirmarPasswordState === "error"}                                                        
                                                            labelText="Confirmar password"
                                                            id="confirmarPassword"
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                onChange: event => {
                                                                    if (verifyLength(event.target.value, 1)) {
                                                                        setConfirmarPasswordState("success");
                                                                    } else {
                                                                        setConfirmarPasswordState("error");
                                                                    }
                                                                    setConfirmarPassword(event.target.value);
                                                                },
                                                                autoComplete: "off",
                                                                value: confirmarPassword,
                                                                type: showConfirmarPassword ? 'text' : 'password',
                                                                endAdornment: (
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => visibilityButtonClick("confirmarPassword")}
                                                                    >
                                                                        {showConfirmarPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                )                                                                
                                                            }}                                                             
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                                <Button color="info" className={classes.updateProfileButton} disabled={loading != null} onClick={updatePasswordClick}>
                                                    Actualizar password
                                                </Button>
                                                <Clearfix />                                                   
                                                </>
                                            )
                                        },                                        
                                        {
                                            tabButton: "Avatar",
                                            tabIcon: Face,
                                            tabContent: (
                                                <>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <Avatar
                                                            width={250}
                                                            height={250}
                                                            onCrop={onCropAvatar}
                                                            onClose={onCloseAvatar}
                                                            onBeforeFileLoad={onBeforeFileLoadAvatar}
                                                            label="Selecciona una imagen"
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={5}>
                                                        <img src={previewAvatar} style={{ paddingTop: "15px", paddingBottom: "15px" }}alt="Default" />
                                                    </GridItem>
                                                </GridContainer>
                                                <Button color="info" className={classes.updateProfileButton} disabled={loading != null} onClick={updateAvatarClick}>
                                                    Actualizar avatar
                                                </Button>
                                                <Button color="warning" className={classes.updateProfileButton} disabled={!previewAvatar} onClick={removeAvatarClick}>
                                                    Quitar avatar
                                                </Button>                                                
                                                <Clearfix />                                                   
                                                </>
                                            )
                                        }
                                        ]}
                                    />                                    
                                </GridItem>                                
                            </GridContainer>
                        </CardBody>
                        {loading}                    
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        idUsuario: state.auth.idUsuario,
        usuario: state.auth.usuario,
        data: state.catalogoEmpleados.data,        
        success: state.catalogoEmpleados.successMessage,
        error: state.catalogoEmpleados.error,
        loading: state.catalogoEmpleados.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (id) => dispatch(get(id)),
        updateNombreCorto: (id, nombreCorto) => dispatch(updateNombreCorto(id, nombreCorto)),
        updatePassword: (id, usuario, passwordActual, passwordNuevo, confirmarPassword) => dispatch(updatePassword(id, usuario, passwordActual, passwordNuevo, confirmarPassword)),
        updateAvatar: (id, avatar) => dispatch(updateAvatar(id, avatar)),
        clearMessages: () => dispatch(clearMessages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MiCuenta);