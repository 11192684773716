// function that verifies if a string has a given length or not
export const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

export const verifyDecimalNumber = value => {
  var numberRex = new RegExp("^[0-9]+(\.[0-9]{1,2})?$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};  