import http from '../../http';

export const getListForSelect = () => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
      
        http.get(`catalogoestados/listadoselect`, config)        
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_ESTADOS_LIST_SELECT", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_ESTADOS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            }); 
    }
}

export const clearMessages = () => {
    return (dispatch, getState) => {
        dispatch({type: "CATALOGO_ESTADOS_LIMPIAR_MENSAJES"});
    }
}