import { combineReducers } from "redux";
import authReducer from "./authReducer.js"
import catalogoEmpleadosReducer from "./catalogoEmpleadosReducer.js";
import catalogoTipoObrasReducer from "./catalogoTipoObrasReducer.js";
import censoObrasReducer from "./censoObrasReducer.js";
import catalogoEstadosReducer from "./catalogoEstadosReducer.js";
import catalogoMunicipiosReducer from "./catalogoMunicipiosReducer.js";

const rootReducer = combineReducers({
    auth: authReducer,
    catalogoEmpleados: catalogoEmpleadosReducer,
    catalogoTipoObras: catalogoTipoObrasReducer,
    censoObras: censoObrasReducer,
    catalogoEstados: catalogoEstadosReducer,
    catalogoMunicipios: catalogoMunicipiosReducer,
});

export default rootReducer;