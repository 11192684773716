import React from "react";
// core components
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

const initState = {
    genericList: null,
    reloadList: false,
    data: null,
    photo: null,
    location: null,
    successMessage: null,
    listError: null,
    error: null,
    loading: null,
}

const censoObrasReducer = (state = initState, action) => {
    
    switch (action.type) {
        case "CENSO_OBRAS_LIST":
            return {
                ...state,
                genericList: action.payload.data,
                reloadList: false,
                loading: null,
                listError: null,
            }        
        case "CENSO_OBRAS_GET":
            return {
                ...state,
                data: action.payload.data,
                loading: null,
            }
        case "CENSO_OBRAS_GET_PHOTO":
            return  {
                ...state,
                photo: action.payload.data,
                loading: null,                
            }
        case "CENSO_OBRAS_GET_LOCATION":
            return  {
                ...state,
                location: action.payload.data,
                loading: null,                
            }                        
        case "CENSO_OBRAS_GUARDADO":
            return {
                ...state,
                successMessage: action.payload.mensaje,
                reloadList: true,
                loading: null,
            }                
        case "CENSO_OBRAS_STATUS_ACTUALIZADO":
            return {
                ...state,
                reloadList: true,
                loading: null,
            }             
        case "CENSO_OBRAS_ERROR":
            return {
                ...state,
                error: action.payload.error,   
                loading: null,                     
            }
        case "CENSO_OBRAS_LIST_ERROR":
            return {
                ...state,
                listError: action.payload.error,   
                loading: null,                     
            }
        case "CENSO_OBRAS_LIMPIAR_MENSAJES": {
            return {
                ...state,
                successMessage: null,
                error: null,
                listError: null,
            }
        }            
        case "CENSO_OBRAS_LOADING": {
            return {
                ...state,
                loading: (<CustomLinearProgress color="info" />),
            }
        }                        
        default:
            return state;
    }
}

export default censoObrasReducer;