/*eslint-disable*/
import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { verifyLength } from "../../../utils/commonFunctions";
import { get, save, clearMessages } from "../../../redux/actions/catalogoTipoObrasActions";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

function TipoObrasForm(props) {
    const [recordId, setRecordId] = React.useState(props.recordId);
    const [descripcion, setDescripcion] = React.useState("");
    const [descripcionState, setDescripcionState] = React.useState("");
    const [returnToList, setReturnToList] = React.useState(false);

    const { loading, success, error } = props;

    React.useEffect(() => {       
        if (recordId > 0) {
            props.getData(recordId);
        }
    }, [recordId]);

    React.useEffect(() => {
        const formData = props.data;
        if (formData && recordId > 0) {
            setDescripcion(formData.Descripcion);
        }
    }, [props.data]);

    const clean = () => {
        setRecordId(0);
        setDescripcion("");
        setDescripcionState("");
      }

    const returnClick = () => {
        props.returnToList();
    }

    const saveClick = (bReturnToList) => {
        var bFormIsValid = true;
        if (descripcion === "") {
            setDescripcionState("error");
            bFormIsValid = false;
        }
        if (bFormIsValid) {
            setReturnToList(bReturnToList);
            props.save(recordId, descripcion);
        }
    };

    const classes = useStyles();

    const hideSuccessAlert = () => {
        props.clearMessages();        
        if(returnToList){
            props.returnToList();
        }
        else{            
            clean();
        }
    };

    const hideErrorAlert = () => {
        props.clearMessages();
    };

    const successMessage = !success ? null : (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Correcto!"
          onConfirm={() => hideSuccessAlert(true)}
          onCancel={() => hideSuccessAlert(true)}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.success}
        </SweetAlert>
      );
    
      const errorMessage = !error ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => hideErrorAlert()}
          onCancel={() => hideErrorAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.error}
        </SweetAlert>
      );   

    return (
        <GridContainer>            
            {successMessage}
            {errorMessage}
            <GridItem xs={12} sm={12} md={6}>
                <form>
                    {loading}
                    <GridContainer>                        
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                success={descripcionState === "success"}
                                error={descripcionState === "error"}
                                labelText="Descripcion *"
                                id="descripcion"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        if (verifyLength(event.target.value, 1)) {
                                            setDescripcionState("success");
                                        } else {
                                            setDescripcionState("error");
                                        }
                                        setDescripcion(event.target.value);
                                    },
                                    autoComplete: "off",
                                    value: descripcion
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <div className={classes.formCategory}>
                        <small>*</small> Campos obligatorios
                    </div>
                    <Button color="info" onClick={() => saveClick(true)} className={classes.registerButton} disabled={loading != null}>
                        Guardar
                    </Button>
                    <Button color="info" onClick={() => saveClick(false)} className={classes.registerButton} disabled={loading != null}>
                        Guardar y agregar otro
                    </Button>
                    <Button color="warning" onClick={returnClick} className={classes.registerButton} disabled={loading != null}>
                        Regresar
                    </Button>                    
                </form>                
            </GridItem>            
        </GridContainer>        
    );
}

const mapStateToProps = (state) => {
    return {
        data: state.catalogoTipoObras.data,
        success: state.catalogoTipoObras.successMessage,
        error: state.catalogoTipoObras.error,
        loading: state.catalogoTipoObras.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (id) => dispatch(get(id)),
        save: (id, descripcion) => dispatch(save(id, descripcion)),
        clearMessages: () => dispatch(clearMessages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TipoObrasForm);