import http from '../../http';
import store from '../../redux/store';

export const getListForTable = () => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
    
        dispatch({ type: 'CATALOGO_EMPLEADOS_LOADING' });       

        http.get(`catalogoempleados/listado`, config)        
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_EMPLEADOS_LIST", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_EMPLEADOS_LIST_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            }); 
    }
}

export const get = (id) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
   
        dispatch({ type: 'CATALOGO_EMPLEADOS_LOADING' });
       
        http.get(`catalogoempleados/${id}`, config)
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_EMPLEADOS_GET", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_EMPLEADOS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const updateStatus = (id, status) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, status });   
    
        dispatch({ type: 'CATALOGO_EMPLEADOS_LOADING' });
        
        http.put(`catalogoempleados/updatestatus`, parameters, config)
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_EMPLEADOS_STATUS_ACTUALIZADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_EMPLEADOS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const save = (id, nombre, apellidoPaterno, apellidoMaterno, nombreCorto, perfil, zona, usuarioLogin, contrasenaLogin, confirmarContrasena) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, nombre, apellidoPaterno, apellidoMaterno, nombreCorto, perfil, zona, usuarioLogin, contrasenaLogin, confirmarContrasena });
        
        dispatch({ type: 'CATALOGO_EMPLEADOS_LOADING' });
        
        http.post(`catalogoempleados`, parameters, config)
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_EMPLEADOS_GUARDADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_EMPLEADOS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const updateNombreCorto = (id, nombreCorto) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, nombreCorto });        
    
        dispatch({ type: 'CATALOGO_EMPLEADOS_LOADING' });
        
        http.put(`catalogoempleados/updatenombrecorto`, parameters, config)
            .then(function (response) {
                store.getState().auth.nombreCorto = nombreCorto;

                dispatch({
                    type: "CATALOGO_EMPLEADOS_NOMBRE_CORTO_ACTUALIZADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_EMPLEADOS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const updatePassword = (id, usuario, passwordActual, passwordNuevo, confirmarPassword) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, usuario, passwordActual, passwordNuevo, confirmarPassword });        
    
        dispatch({ type: 'CATALOGO_EMPLEADOS_LOADING' });
        
        http.put(`catalogoempleados/updatepassword`, parameters, config)
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_EMPLEADOS_PASSWORD_ACTUALIZADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_EMPLEADOS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const updateAvatar = (id, avatar) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, avatar });        
    
        dispatch({ type: 'CATALOGO_EMPLEADOS_LOADING' });
       
        http.put(`catalogoempleados/updateavatar`, parameters, config)
            .then(function (response) {
                store.getState().auth.avatar = avatar;

                dispatch({
                    type: "CATALOGO_EMPLEADOS_AVATAR_ACTUALIZADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_EMPLEADOS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const resetPassword = (id, password, confirmarPassword) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, password, confirmarPassword });        
    
        dispatch({ type: 'CATALOGO_EMPLEADOS_LOADING' });
        
        http.put(`catalogoempleados/resetearpassword`, parameters, config)
            .then(function (response) {
                dispatch({
                    type: "CATALOGO_EMPLEADOS_PASSWORD_RESETEADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CATALOGO_EMPLEADOS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const clearMessages = () => {
    return (dispatch, getState) => {
        dispatch({type: "CATALOGO_EMPLEADOS_LIMPIAR_MENSAJES"});
    }
}