import React from "react";
import imageCompression from 'browser-image-compression';
// used for making the prop types of this component
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
// @material-ui/icons
import Error from "@material-ui/icons/Error";

import defaultImage from "assets/img/image_placeholder.jpg";

import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

export default function CensoObrasPhoto(props) {
  const classes = useStyles();
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(props.image ? props.image : defaultImage);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {  
    props.scrollToTop();
  }, []);

  let fileInput = React.createRef();

  const compressOptions = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1024,
    useWebWorker: true
  }

  const handleImageChange = async e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    try {
      const compressedFile = await imageCompression(file, compressOptions);
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      reader.onloadend = () => {
        setFile(compressedFile);
        setImagePreviewUrl(reader.result);
      };
      if (compressedFile) {
        reader.readAsDataURL(compressedFile);
      }
      setError(null)
    } catch (error) {            
      setError("Ocurrio un error. Verifique que el archivo seleccionado sea una imagen.");
      console.log(error);
    }
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(defaultImage);
    fileInput.current.value = null;
  };
  const returnClick = () => {
      props.returnToForm();
  }
  const acceptClick = () => {
      props.imageSelected(imagePreviewUrl);
      props.returnToForm();
  }
  const errorNotification = error ? (
    <Snackbar
      place="tc"
      color="danger"
      icon={Error}
      message={error}
      open
    />
  ) : null;    
  let { addButtonProps, changeButtonProps, removeButtonProps } = props;
  return (
    <>    
    {errorNotification}
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className="fileinput text-center">
          <input type="file" accept="image/*" onChange={handleImageChange} ref={fileInput} />
          <div className={"thumbnail"}>
            <img src={imagePreviewUrl} alt="..." />
          </div>
          <div>
            {file === null ? (
              <Button {...addButtonProps} onClick={() => handleClick()}>
                Seleccione una imagen
              </Button>
            ) : (
              <span>
                <Button {...changeButtonProps} onClick={() => handleClick()}>
                  Cambiar
                </Button>            
                <Button {...removeButtonProps} onClick={() => handleRemove()}>
                  <i className="fas fa-times" /> Quitar
                </Button>
              </span>
            )}
          </div>   
        </div>        
      </GridItem>
    </GridContainer>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>          
          <Button color="info" onClick={acceptClick} className={classes.registerButton} disabled={file === null}>
              Aceptar
          </Button>
          <Button color="warning" onClick={returnClick} className={classes.registerButton}>
              Regresar
          </Button>            
      </GridItem>            
  </GridContainer>   
  </>
  );
}

CensoObrasPhoto.propTypes = {
  addButtonProps: PropTypes.object,  
  removeButtonProps: PropTypes.object
};
