/*eslint-disable*/
import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { verifyLength } from "../../../utils/commonFunctions";
import { get, resetPassword, clearMessages } from "../../../redux/actions/catalogoEmpleadosActions";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';

// material ui icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

function ResetearPasswordForm(props) {
    const [recordId] = React.useState(props.recordId);
    const [usuarioLogin, setUsuarioLogin] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordState, setPasswordState] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [confirmarPassword, setConfirmarPassword] = React.useState("");
    const [confirmarPasswordState, setConfirmarPasswordState] = React.useState("");
    const [showConfirmarPassword, setShowConfirmarPassword] = React.useState(false);

    const { loading, success, error } = props;

    React.useEffect(() => {       
        if (recordId > 0) {
            props.getData(recordId);
        }
    }, [recordId]);

    React.useEffect(() => {
        const formData = props.data;
        if (formData && recordId > 0) {
            setUsuarioLogin(formData.UsuarioLogin);
        }
    }, [props.data]);

    const returnClick = () => {
        props.returnToList();
    }

    const updatePasswordClick = () => {
        var bFormIsValid = true;    
        if (password === "") {
            setPasswordState("error");
            bFormIsValid = false;
        }        
        if (confirmarPassword === "") {
            setConfirmarPasswordState("error");
            bFormIsValid = false;
        }
        
        if(bFormIsValid){
          props.resetPassword(recordId, password, confirmarPassword);
        } 
    };

    const visibilityButtonClick = (field) => {
        switch (field) {
            case "password":
                setShowPassword(!showPassword);
                break;
            case "confirmarPassword":
                setShowConfirmarPassword(!showConfirmarPassword);
                break;
        }
    }

    const classes = useStyles();

    const hideSuccessAlert = () => {
        props.clearMessages();        
        props.returnToList();
    };

    const hideErrorAlert = () => {
        props.clearMessages();
    };

    const successMessage = !success ? null : (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Correcto!"
          onConfirm={() => hideSuccessAlert(true)}
          onCancel={() => hideSuccessAlert(true)}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.success}
        </SweetAlert>
      );
    
      const errorMessage = !error ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => hideErrorAlert()}
          onCancel={() => hideErrorAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.error}
        </SweetAlert>
      );   

    return (
        <GridContainer>            
            {successMessage}
            {errorMessage}
            <GridItem xs={12} sm={12} md={6}>
                <form>
                    {loading}
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Usuario *"
                                id="usuario"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    disabled: true,                   
                                    value: usuarioLogin
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                success={passwordState === "success"}
                                error={passwordState === "error"}
                                labelText="Password"
                                id="password"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        if (verifyLength(event.target.value, 1)) {
                                            setPasswordState("success");
                                        } else {
                                            setPasswordState("error");
                                        }
                                        setPassword(event.target.value);
                                    },
                                    autoComplete: "off",
                                    value: password,
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => visibilityButtonClick("password")}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                success={confirmarPasswordState === "success"}
                                error={confirmarPasswordState === "error"}
                                labelText="Confirmar password"
                                id="confirmarPassword"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        if (verifyLength(event.target.value, 1)) {
                                            setConfirmarPasswordState("success");
                                        } else {
                                            setConfirmarPasswordState("error");
                                        }
                                        setConfirmarPassword(event.target.value);
                                    },
                                    autoComplete: "off",
                                    value: confirmarPassword,
                                    type: showConfirmarPassword ? 'text' : 'password',
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => visibilityButtonClick("confirmarPassword")}
                                        >
                                            {showConfirmarPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <div className={classes.formCategory}>
                        <small>*</small> Campos obligatorios
                    </div>
                    <Button color="info" onClick={() => updatePasswordClick(true)} className={classes.registerButton} disabled={loading != null}>
                        Guardar
                    </Button>
                    <Button color="warning" onClick={returnClick} className={classes.registerButton} disabled={loading != null}>
                        Regresar
                    </Button>                    
                </form>                
            </GridItem>            
        </GridContainer>        
    );
}

const mapStateToProps = (state) => {
    return {
        data: state.catalogoEmpleados.data,
        success: state.catalogoEmpleados.successMessage,
        error: state.catalogoEmpleados.error,
        loading: state.catalogoEmpleados.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (id) => dispatch(get(id)),
        resetPassword: (id, password, confirmarPassword) => dispatch(resetPassword(id, password, confirmarPassword)),
        clearMessages: () => dispatch(clearMessages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetearPasswordForm);