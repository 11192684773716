import http from '../../http';
import store from '../../redux/store';

export const getListForTable = () => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
    
        dispatch({ type: 'CENSO_OBRAS_LOADING' });
        
        http.get(`censoobras/listado`, config)        
            .then(function (response) {
                dispatch({
                    type: "CENSO_OBRAS_LIST", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CENSO_OBRAS_LIST_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            }); 
    }
}

export const get = (id) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
   
        dispatch({ type: 'CENSO_OBRAS_LOADING' });
       
        http.get(`censoobras/${id}`, config)
            .then(function (response) {
                dispatch({
                    type: "CENSO_OBRAS_GET", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CENSO_OBRAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const getPhoto = (id) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
   
        dispatch({ type: 'CENSO_OBRAS_LOADING' });
       
        http.get(`censoobras/foto/${id}`, config)
            .then(function (response) {
                dispatch({
                    type: "CENSO_OBRAS_GET_PHOTO", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CENSO_OBRAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const getLocation = (id) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };
   
        dispatch({ type: 'CENSO_OBRAS_LOADING' });
       
        http.get(`censoobras/coordenadas/${id}`, config)
            .then(function (response) {
                dispatch({
                    type: "CENSO_OBRAS_GET_LOCATION", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CENSO_OBRAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const updateStatus = (id, status) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, status });   
    
        dispatch({ type: 'CENSO_OBRAS_LOADING' });
        
        http.put(`censoobras/updatestatus`, parameters, config)
            .then(function (response) {
                dispatch({
                    type: "CENSO_OBRAS_STATUS_ACTUALIZADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CENSO_OBRAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const save = (id, calle, numeroExterior, numeroInterior, colonia, codigoPostal, localidad, municipio, estado, metros, idTipoObra, clase, 
    porcentajeAvance, zona, folioDeteccion, fechaDeteccion, registroObra, pooc, fechaPOOC, prto, fechaPRTO, regularizada, latitud, longitud, fotografia) => {
    return (dispatch, getState) => {
        var config = { headers: { 'Content-Type': 'application/json' } };

        var parameters = JSON.stringify({ id, calle, numeroExterior, numeroInterior, colonia, codigoPostal, localidad, municipio, estado, metros, idTipoObra, clase, 
            porcentajeAvance, zona, folioDeteccion, fechaDeteccion, registroObra, pooc, fechaPOOC, prto, fechaPRTO, regularizada, latitud, longitud, fotografia, idEmpleado: store.getState().auth.idUsuario });
        
        dispatch({ type: 'CENSO_OBRAS_LOADING' });
        
        http.post(`censoobras`, parameters, config)
            .then(function (response) {
                dispatch({
                    type: "CENSO_OBRAS_GUARDADO", payload: {
                        mensaje: response.data.mensaje,
                    }
                });
            })
            .catch(error => {
                var errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                } else {
                    errorMessage = `No se obtuvo respuesta del servidor ${error}`;
                }
                dispatch({ type: 'CENSO_OBRAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                
            });
    }
}

export const clearMessages = () => {
    return (dispatch, getState) => {
        dispatch({type: "CENSO_OBRAS_LIMPIAR_MENSAJES"});
    }
}