/*eslint-disable*/
import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { verifyLength } from "../../../utils/commonFunctions";
import { get, save, clearMessages } from "../../../redux/actions/catalogoEmpleadosActions";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';

// material ui icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

function EmpleadosForm(props) {
    const [recordId, setRecordId] = React.useState(props.recordId);
    const [nombre, setNombre] = React.useState("");
    const [nombreState, setNombreState] = React.useState("");
    const [apellidoPaterno, setApellidoPaterno] = React.useState("");
    const [apellidoPaternoState, setApellidoPaternoState] = React.useState("");
    const [apellidoMaterno, setApellidoMaterno] = React.useState("");
    const [nombreCorto, setNombreCorto] = React.useState("");
    const [nombreCortoState, setNombreCortoState] = React.useState("");
    const [perfil, setPerfil] = React.useState("");
    const [perfilState, setPerfilState] = React.useState("");
    const [zona, setZona] = React.useState("");
    const [zonaState, setZonaState] = React.useState("");
    const [usuarioLogin, setUsuarioLogin] = React.useState("");
    const [usuarioLoginState, setUsuarioLoginState] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordState, setPasswordState] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [confirmarPassword, setConfirmarPassword] = React.useState("");
    const [confirmarPasswordState, setConfirmarPasswordState] = React.useState("");
    const [showConfirmarPassword, setShowConfirmarPassword] = React.useState(false);
    const [returnToList, setReturnToList] = React.useState(false);

    const { loading, success, error } = props;

    const perfilList = [
        { value: 'Administrador', label: 'Administrador' },
        { value: 'Jefe de Departamento', label: 'Jefe de Departamento' },
        { value: 'Coordinador', label: 'Coordinador' },
        { value: 'Sensor', label: 'Sensor' },
    ];

    const zonaList = [
        { value: 'Zona 1', label: 'Zona 1' },
        { value: 'Zona 2', label: 'Zona 2' },
        { value: 'Zona 3', label: 'Zona 3' },
        { value: 'Zona 4', label: 'Zona 4' },
        { value: 'Zona 5', label: 'Zona 5' },
        { value: 'Zona Norte', label: 'Zona Norte' },
        { value: 'Zona Sur', label: 'Zona Sur' },
        { value: 'Obra Publica', label: 'Obra Publica' },
    ];

    React.useEffect(() => {       
        if (recordId > 0) {
            props.getData(recordId);
        }
    }, [recordId]);

    React.useEffect(() => {
        const formData = props.data;
        if (formData && recordId > 0) {
            setNombre(formData.Nombre);
            setApellidoPaterno(formData.ApellidoPaterno);
            setApellidoMaterno(formData.ApellidoMaterno);
            setNombreCorto(formData.NombreCorto);
            setPerfil({ value: formData.Perfil, label: formData.Perfil });
            setZona({ value: formData.Zona, label: formData.Zona });
            setUsuarioLogin(formData.UsuarioLogin);
            setPassword("*****");
            setConfirmarPassword("*****");
        }
    }, [props.data]);

    const clean = () => {
        setRecordId(0);
        setNombre("");
        setNombreState("");
        setApellidoPaterno("");
        setApellidoPaternoState("");
        setApellidoMaterno("");
        setNombreCorto("");
        setNombreCortoState("");
        setPerfil("");
        setPerfilState("");
        setZona("");
        setZonaState("");
        setUsuarioLogin("");
        setUsuarioLoginState("");
        setPassword("");
        setPasswordState("");
        setConfirmarPassword("");
        setConfirmarPasswordState("");
      }

    const returnClick = () => {
        props.returnToList();
    }

    const saveClick = (bReturnToList) => {
        var bFormIsValid = true;
        if (nombre === "") {
            setNombreState("error");
            bFormIsValid = false;
        }
        if (apellidoPaterno === "") {
            setApellidoPaternoState("error");
            bFormIsValid = false;
        }
        if (nombreCorto === "") {
            setNombreCortoState("error");
            bFormIsValid = false;
        }
        if (perfil === "") {
            setPerfilState("error");
            bFormIsValid = false;
        }
        if (zona === "") {
            setZonaState("error");
            bFormIsValid = false;
        }
        if (usuarioLogin === "") {
            setUsuarioLoginState("error");
            bFormIsValid = false;
        }
        if (password === "") {
            setPasswordState("error");
            bFormIsValid = false;
        }
        if (confirmarPassword === "") {
            setConfirmarPasswordState("error");
            bFormIsValid = false;
        }

        if (bFormIsValid) {
            setReturnToList(bReturnToList);
            props.save(recordId, nombre, apellidoPaterno, apellidoMaterno, nombreCorto, perfil.value, zona.value, usuarioLogin, password, confirmarPassword);
        }
    };

    const handlePerfilChange = (selectedOption) => {
        setPerfil(selectedOption);
    }

    const handleZonaChange = (selectedOption) => {
        setZona(selectedOption);
    }

    const perfilStyles = {
        control: styles => ({ ...styles, marginTop: '15px' }),
        placeholder: styles => ({ ...styles, color: perfilState === "error" ? '#f44336' : perfilState === "success" ? '#4caf50' : '#AAAAAA' }),
    };

    const zonaStyles = {
        control: styles => ({ ...styles, marginTop: '15px' }),
        placeholder: styles => ({ ...styles, color: zonaState === "error" ? '#f44336' : zonaState === "success" ? '#4caf50' : '#AAAAAA' }),
    };

    const visibilityButtonClick = (field) => {
        switch (field) {
            case "password":
                setShowPassword(!showPassword);
                break;
            case "confirmarPassword":
                setShowConfirmarPassword(!showConfirmarPassword);
                break;
        }
    }

    const classes = useStyles();

    const hideSuccessAlert = () => {
        props.clearMessages();        
        if(returnToList){
            props.returnToList();
        }
        else{            
            clean();
        }
    };

    const hideErrorAlert = () => {
        props.clearMessages();
    };

    const successMessage = !success ? null : (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Correcto!"
          onConfirm={() => hideSuccessAlert(true)}
          onCancel={() => hideSuccessAlert(true)}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.success}
        </SweetAlert>
      );
    
      const errorMessage = !error ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => hideErrorAlert()}
          onCancel={() => hideErrorAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.error}
        </SweetAlert>
      );   

    return (
        <GridContainer>            
            {successMessage}
            {errorMessage}
            <GridItem xs={12} sm={12} md={6}>
                <form>
                    {loading}
                    <GridContainer>                        
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                success={nombreState === "success"}
                                error={nombreState === "error"}
                                labelText="Nombre *"
                                id="nombre"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        if (verifyLength(event.target.value, 1)) {
                                            setNombreState("success");
                                        } else {
                                            setNombreState("error");
                                        }
                                        setNombre(event.target.value);
                                    },
                                    autoComplete: "off",
                                    value: nombre
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                success={apellidoPaternoState === "success"}
                                error={apellidoPaternoState === "error"}
                                labelText="Apellido paterno *"
                                id="apellidoPaterno"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        if (verifyLength(event.target.value, 1)) {
                                            setApellidoPaternoState("success");
                                        } else {
                                            setApellidoPaternoState("error");
                                        }
                                        setApellidoPaterno(event.target.value);
                                    },
                                    autoComplete: "off",
                                    value: apellidoPaterno
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Apellido materno"
                                id="apellidoMaterno"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        setApellidoMaterno(event.target.value);
                                    },
                                    autoComplete: "off",
                                    value: apellidoMaterno
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                success={nombreCortoState === "success"}
                                error={nombreCortoState === "error"}
                                labelText="Nombre corto *"
                                id="nombreCorto"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        if (verifyLength(event.target.value, 1)) {
                                            setNombreCortoState("success");
                                        } else {
                                            setNombreCortoState("error");
                                        }
                                        setNombreCorto(event.target.value);
                                    },
                                    autoComplete: "off",
                                    value: nombreCorto
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <Select
                                isClearable
                                options={perfilList}
                                onChange={handlePerfilChange}
                                placeholder="Perfil *"
                                value={perfil}
                                styles={perfilStyles}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <Select
                                isClearable
                                options={zonaList}
                                onChange={handleZonaChange}
                                placeholder="Zona *"
                                value={zona}
                                styles={zonaStyles}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                success={usuarioLoginState === "success"}
                                error={usuarioLoginState === "error"}
                                labelText="Usuario *"
                                id="usuario"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        if (verifyLength(event.target.value, 1)) {
                                            setUsuarioLoginState("success");
                                        } else {
                                            setUsuarioLoginState("error");
                                        }
                                        setUsuarioLogin(event.target.value);
                                    },
                                    disabled: recordId > 0,
                                    autoComplete: "off",
                                    value: usuarioLogin
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                success={passwordState === "success"}
                                error={passwordState === "error"}
                                labelText="Password"
                                id="password"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        if (verifyLength(event.target.value, 1)) {
                                            setPasswordState("success");
                                        } else {
                                            setPasswordState("error");
                                        }
                                        setPassword(event.target.value);
                                    },
                                    disabled: recordId > 0,
                                    autoComplete: "off",
                                    value: password,
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment: (
                                        <IconButton
                                            disabled={recordId > 0}
                                            aria-label="toggle password visibility"
                                            onClick={() => visibilityButtonClick("password")}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                success={confirmarPasswordState === "success"}
                                error={confirmarPasswordState === "error"}
                                labelText="Confirmar password"
                                id="confirmarPassword"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        if (verifyLength(event.target.value, 1)) {
                                            setConfirmarPasswordState("success");
                                        } else {
                                            setConfirmarPasswordState("error");
                                        }
                                        setConfirmarPassword(event.target.value);
                                    },
                                    disabled: recordId > 0,
                                    autoComplete: "off",
                                    value: confirmarPassword,
                                    type: showConfirmarPassword ? 'text' : 'password',
                                    endAdornment: (
                                        <IconButton
                                            disabled={recordId > 0}
                                            aria-label="toggle password visibility"
                                            onClick={() => visibilityButtonClick("confirmarPassword")}
                                        >
                                            {showConfirmarPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <div className={classes.formCategory}>
                        <small>*</small> Campos obligatorios
                    </div>
                    <Button color="info" onClick={() => saveClick(true)} className={classes.registerButton} disabled={loading != null}>
                        Guardar
                    </Button>
                    <Button color="info" onClick={() => saveClick(false)} className={classes.registerButton} disabled={loading != null}>
                        Guardar y agregar otro
                    </Button>
                    <Button color="warning" onClick={returnClick} className={classes.registerButton} disabled={loading != null}>
                        Regresar
                    </Button>                    
                </form>                
            </GridItem>            
        </GridContainer>        
    );
}

const mapStateToProps = (state) => {
    return {
        data: state.catalogoEmpleados.data,
        success: state.catalogoEmpleados.successMessage,
        error: state.catalogoEmpleados.error,
        loading: state.catalogoEmpleados.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (id) => dispatch(get(id)),
        save: (id, nombre, apellidoPaterno, apellidoMaterno, nombreCorto, perfil, zona, usuarioLogin, contrasenaLogin, confirmarContrasena) => dispatch(save(id, nombre, apellidoPaterno, apellidoMaterno, nombreCorto, perfil, zona, usuarioLogin, contrasenaLogin, confirmarContrasena)),
        clearMessages: () => dispatch(clearMessages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmpleadosForm);