import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
// @material-ui/icons
import Error from "@material-ui/icons/Error";

import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

// maps
const libraries = ["places"];

const mapContainerStyle = {
    height: "100vh",
    width: "100%"
};

function Locate(props){
    const onRelocateClick = () => {
        props.onRelocateClick();
    }

    return (
        <Button size="sm" style={{position: "absolute", top: "0.8rem", right: "4.5rem", background: "none", border: "none", zIndex: 10}} onClick={onRelocateClick}>
            <img src="compass.svg" alt="Ubicación actual" style={{height: "45px", width: "45px"}} />
        </Button>             
    );
}

function CensoObrasMap(props) {
    const classes = useStyles();
    const [mapError, setMapError] = React.useState(null);
    const [latitude, setLatitude] = React.useState(0);
    const [longitude, setLongitude] = React.useState(0);
    const [mapPosition, setMapPosition] = React.useState({lat: 23.249414, lng: -106.411140,}); // Mazatlan by default
    const [markers, setMarkers] = React.useState([]); // this module will only handle one marker
    const [relocate, setRelocate] = React.useState(false);

    const { isReadOnly } = props;

    React.useEffect(() => {  
        props.scrollToTop();     
        getLocation();       
    }, []);    

    //Google maps tutorial: https://www.youtube.com/watch?v=WZcxJGmLbSo&feature=youtu.be
    const onMapClick = React.useCallback((event) => {
        if(isReadOnly){
            return;
        }
        setMarkers([{
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            time: new Date(),
        }])
        setLatitude(event.latLng.lat());
        setLongitude(event.latLng.lng());         
    }, []);

    //https://www.w3schools.com/html/html5_geolocation.asp
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(setLocation, showLocationError);
        } else {
            setMapError("La geolocalización no es soportada por este navegador.");
        }
    }  
    
    const setLocation = location => {
        if(props.latitude === null || props.longitude === null || relocate === true){
            setRelocate(false);
            updateMapCords(location.coords.latitude, location.coords.longitude);
        }
        else{
            updateMapCords(props.latitude, props.longitude);
        }
    }
    
    const updateMapCords = (latitude, longitude) => {
        setLatitude(latitude);
        setLongitude(longitude);         
        setMapPosition({
            lat: parseFloat(latitude), 
            lng: parseFloat(longitude),
        });
        setMarkers([{
            lat: parseFloat(latitude), 
            lng: parseFloat(longitude),
            time: new Date(),
        }]);

    }
    
    const showLocationError = error => {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            setMapError("El usuario denego el permiso para geolocalización.");
            break;
          case error.POSITION_UNAVAILABLE:
            setMapError("Informacion de la ubicación no disponible.");
            break;
          case error.TIMEOUT:
            setMapError("La petición de la ubicacion a excedido el tiempo de espera.");
            break;
          case error.UNKNOWN_ERROR:
            setMapError("Ocurrio un error desconocido.");
            break;
        }
    }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCA4bO4Jz8qOflcA_K9I32U8O1PJrFoESQ",
        libraries,
    });

    if(loadError) setMapError("Error al cargar el mapa");
    if(!isLoaded) return "...";   
    
    const returnClick = () => {
        props.onReturn();
    }

    const acceptClick = () => {
        props.onLocationSelected(latitude, longitude);
        props.onReturn();
    }

    const errorNotification = mapError ? (
        <Snackbar
          place="tc"
          color="danger"
          icon={Error}
          message={mapError}
          open
        />
      ) : null;

    const relocateClick = () => {
        setRelocate(true);
        getLocation();
    }

    return (
        <>     
        {errorNotification}   
        <GridContainer>            
            <GridItem xs={12} sm={12} md={12}>
                <Locate onRelocateClick={relocateClick}/>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={17}
                    center={mapPosition}
                    onClick={onMapClick}
                    options={{ scrollwheel: false}}
                >
                    {markers.map(marker => (
                        <Marker
                            key={marker.time.toISOString()}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            icon={{
                                url: "/redmarker.svg",
                                scaledSize: new window.google.maps.Size(50, 50),
                            }}
                        />
                    ))}
                </GoogleMap>
            </GridItem>
        </GridContainer>  
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {!isReadOnly ? (
                    <Button color="info" onClick={acceptClick} className={classes.registerButton} disabled={errorNotification !== null}>
                        Aceptar
                    </Button>
                    ) : null
                }
                <Button color="warning" onClick={returnClick} className={classes.registerButton}>
                    Regresar
                </Button>            
            </GridItem>            
        </GridContainer>          
        </>      
    );
}

export default CensoObrasMap;